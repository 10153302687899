<template>
  <div>
    <van-cell to="/chat/page" title="打开聊天窗口"/>
  </div>
</template>

<script>
export default {
  name: "ChatList"
}
</script>

<style scoped>

</style>
